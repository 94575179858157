import React, { useState } from 'react'
import { send } from 'emailjs-com';

export const Contact = () => {
	const initialFormState = { name: "", email: "", message: "" }
	const [form, setForm] = useState(initialFormState)
	const [errorMessage, setErrorMessage] = useState('');
	const { name, email, message } = form

	const [toSend, setToSend] = useState({
		name: '',
		message: '',
		email: '',
	});

	const [success, setSuccess] = useState(false)

	const handleInputChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value })
		setToSend({ ...toSend, [e.target.name]: e.target.value });
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (!validateEmail(email)) {
			setErrorMessage('Your email is invalid')
		} else {
			setErrorMessage("")
		}

		if (!errorMessage) {
			console.log('Submit Form', form);
			send(
				'service_5spicekitchen',
				'template_oqdvl9o',
				toSend,
				'user_WpKxHUa5LRfYa1QCVSAEx'
			)
				.then((response) => {
					console.log('SUCCESS!', response.status, response.text);
					setSuccess(true)
				})
				.catch((err) => {
					console.log('FAILED...', err);
				});
		}

	}


	return (
		<section className="text-gray-900 mt-8 flex flex-col items-center justify-around" id="contactus">
			<div className="flex flex-col items-center justify-between">
				<h2 className="uppercase font-normal text-xl sm:text-3xl">Contact Us</h2>
				<span className="border-b-2 border-yellow-600 h-2 block w-20 mt-4"></span>
				<p className="mt-4 mx-4 leading-relaxed font-light tracking-wide sm:text-lg md:w-8/12">Service is the most important aspect of our business, and we want to be there whenever and wherever you need us.
					We want to hear from you. You can reach us at <a href="tel:+16305292828">630-529-2828</a> or email us at <a href="mailto:info@5spicekitchen.com">info@5spicekitchen.com</a>. You can also fill out the contact form.
				</p>
			</div>
			<form onSubmit={handleSubmit} className="mt-4 mx-3 flex flex-col w-full sm:w-80">
				<label className="mx-3">Name</label>
				<input
					className="border border-gray-400 rounded mt-2 p-2 mx-3"
					type="text"
					name="name"
					placeholder="Enter your name"
					onChange={handleInputChange}
					required
					value={name}
				/>
				<label className="mx-3 mt-2">Email</label>
				<input
					className="border border-gray-400 rounded mt-2 p-2 mx-3"
					name="email"
					type="email"
					placeholder="Enter your email"
					onChange={handleInputChange}
					required
					value={email}
				/>
				<label className="mx-3 mt-2">Message</label>
				<textarea
					className="border border-gray-400 rounded mt-2 p-2 mx-3"
					type="text"
					name="message"
					placeholder="Enter your message"
					onChange={handleInputChange}
					required
					rows="5"
					value={message}
				/>
				{errorMessage && <span className="mx-4 text-red-500">{errorMessage}</span>}
				<div>
					<button type="submit" className="rounded-full bg-yellow-600 p-3 mt-4 mx-3 w-32 text-white outline-none focus:ring-1 focus:ring-yellow-100 active:bg-yellow-700">Send it!</button>
					{success && <span className="text-green-800">Message Sent!</span>}
				</div>
			</form>
		</section>
	)
}


function validateEmail(email) {
	var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}