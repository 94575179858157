import React, { useState, Fragment } from 'react';
import logoBlack from '../img/logo-black-transparent.png';
import { Menu, Transition } from '@headlessui/react';
import foodMenu from '../static/FSK_Dinner_Menu.pdf'
import lunchMenu from '../static/FSK_Lunch_Menu.pdf'
import carryOutMenu from '../static/FSK_CarryOut_Menu.pdf'


const navigation = [
  {
    name: 'ORDER ONLINE',
    href: 'https://order.mealkeyway.com/customer/release/index?mid=763370766f70376b797a4f4d2f5a7a397634636872673d3d#/main',
  },
  // {
  //   name: 'CALL TO ORDER',
  //   href: 'tel:+1630-529-2828',
  // },
  {
    name: 'FOOD MENU',
    href: foodMenu,
  },
  { name: 'DRINK MENU', href: 'https://www.cloudteausa.com/menu' },
  {
    name: 'LUNCH MENU',
    href: lunchMenu,
  },
  { name: 'CARRYOUT MENU', href: carryOutMenu },
  // { name: 'VIRTUAL TOUR', href: '/virtual_tour' },
  // { name: 'GIFT CARDS', href: '#giftcards' },
  // { name: 'NEWS', href: '#news' },
  { name: 'CONTACT US', href: '#contactus' },
  { name: 'ABOUT US', href: '#about' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const NavbarSticky = () => {
  const [open, setOpen] = useState(false);

  return (
    <nav className='fixed inset-x-0 top-0 z-10 bg-white shadow md:flex md:justify-between'>
      <div className='flex items-center justify-between md:max-w-6xl md:m-auto'>
        <a
          href='/'
          className='inline-block outline-none focus:ring-2 focus:ring-yellow-100 focus:ring-inset'
        >
          <img
            className='inline-block w-auto h-12 m-2 sm:h-14 sm:object-contain md:ml-4'
            src={logoBlack}
            alt='logo'
          />
        </a>
        {/* <div className='w-80'></div> */}
        <div className='hidden md:flex md:flex-wrap md:justify-end lg:ml-4'>
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className='block px-4 py-2 text-sm text-gray-900 outline-none focus:ring-2 focus:ring-yellow-100 focus:ring-inset hover:text-yellow-600 md:text-base lg:text-sm'
              style={{ '&:hover': { borderBottom: '2px solid #e67e22' } }}
            >
              {item.name}
            </a>
          ))}
        </div>

        <Menu as='div' role='menu' className='flex items-center md:hidden'>
          <Menu.Button className='outline-none'>
            {open ? (
              <svg
                className='w-6 h-6 m-4 text-gray-900 fill-current sm:w-9 sm:h-9'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
                onClick={() => setOpen(!open)}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h16M4 18h16'
                ></path>
              </svg>
            ) : (
              <svg
                className='w-6 h-6 m-4 text-gray-900 fill-current sm:w-9 sm:h-9'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
                onClick={() => setOpen(!open)}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h16M4 18h16'
                ></path>
              </svg>
            )}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute w-40 py-1 mt-48 origin-top-right bg-white rounded-md shadow-lg right-14 ring-1 ring-black ring-opacity-5 focus:outline-none sm:right-16'>
              {navigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <a
                      href={item.href}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}
                    >
                      {item.name}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </nav>
  );
};
