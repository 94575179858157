import React from 'react';

export const CallToAction = () => {
  return (
    <div className='text-white mx-3 top-2/3 absolute md:ml-4 lg:ml-6'>
      <p className='text-2xl font-normal uppercase tracking-wide sm:text-3xl lg:text-4xl'>
        Pan Asian restaurant
      </p>
      <p className='text-xl text-gray-100 tracking-wide sm:text-2xl'>
        Brought to you by Silver Lake
      </p>
      <div className='mt-5 flex justify-start'>
        <a
          href='https://order.mealkeyway.com/customer/release/index?mid=763370766f70376b797a4f4d2f5a7a397634636872673d3d#/main'
          target='_self'
          role='button'
          rel='noreferrer'
          className='inline-block bg-yellow-600 p-3 rounded-full outline-none focus:ring-2 focus:ring-yellow-100 hover:bg-yellow-500 active:bg-yellow-800 sm:text-lg'
        >
          Order Online
        </a>
        <a
          href='#about'
          role='button'
          className='inline-block p-3 border border-yellow-600 rounded-full text-yellow-600 ml-4 outline-none focus:ring-2 focus:ring-yellow-100 hover:bg-yellow-600 hover:text-white active:bg-yellow-800 sm:text-lg'
        >
          Show me more
        </a>
      </div>
    </div>
  );
};
