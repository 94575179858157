import React from 'react';
import giftcard from '../img/giftcard.png'
// import giftcard from '../img/mothers_special.PNG';

export const GiftCards = () => {
  return (
    <section className="flex items-center justify-around mt-8 text-gray-900 md:w-8/12 md:m-auto md:mt-8" id="giftcards">
    	<div className="flex flex-col items-center justify-between">
    		<h2 className="text-xl font-normal uppercase sm:text-3xl">Gift Cards</h2>
    		<span className="block w-20 h-2 mt-4 border-b-2 border-yellow-600"></span>
    		<div className="">
    			<img className="float-left h-32 mt-4 ml-3 mr-3 shadow-xl rounded-xl" src={giftcard} alt="gift card" />
    			<p className="mx-4 mt-4 font-light leading-relaxed tracking-wide sm:text-lg">Gift Cards are able to be used for in-person dining or to-go orders placed over the phone for pick-up
    			</p>
    		</div>
    	</div>
    </section>
    // <section
    //   className='flex items-center justify-around mt-8 text-gray-900 md:w-5/12 md:m-auto md:mt-8'
    //   id='giftcards'
    // >
    //   <div className='flex flex-col items-center justify-between'>
    //     <h2 className='text-xl font-normal uppercase sm:text-3xl'>
    //       Gift Cards
    //     </h2>
    //     <span className='block w-20 h-2 mt-4 border-b-2 border-yellow-600'></span>
    //     <div className=''>
    //       <LightBox src={giftcard}>
    //         <img
    //           className='float-left mt-4 ml-3 mr-3 shadow-xl cursor-pointer h-72 rounded-xl'
    //           src={giftcard}
    //           alt='gift card'
    //         />
    //       </LightBox>
    //       <p className='mx-4 mt-4 font-light leading-relaxed tracking-wide sm:text-lg'>
    //         Gift Cards are able to be used for in-person dining or to-go orders
    //         placed over the phone for pick-up
    //       </p>
    //     </div>
    //   </div>
    // </section>
  );
};

// const LightBox = ({ children, src, alt, Wrapper = 'div', zIndex = 100 }) => {
//   console.log('src: ', src);
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleIsOpen = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <Wrapper onClick={toggleIsOpen}>
//       {children}
//       {isOpen ? (
//         <div
//           onClick={toggleIsOpen}
//           style={{
//             position: 'fixed',
//             top: '0',
//             left: '0',
//             height: '100vh',
//             width: '100vw',
//             backgroundColor: 'rgba(0,0,0,0.7)',
//             cursor: 'pointer',
//             zIndex,
//           }}
//         >
//           <img
//             src={src}
//             alt={alt}
//             style={{
//               height: '70%',
//               width: 'auto',
// 							margin: "auto",
// 							position: 'relative',
// 							top: '10%'
//             }}
//           />
//         </div>
//       ) : null}
//     </Wrapper>
//   );
// };
