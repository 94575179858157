import React, { useState, Fragment } from 'react';
import logoWhite from '../img/logo-white-transparent.png';
import { Menu, Transition } from '@headlessui/react';
import foodMenu from '../static/FSK_Dinner_Menu.pdf'
import lunchMenu from '../static/FSK_Lunch_Menu.pdf'
import carryOutMenu from '../static/FSK_CarryOut_Menu.pdf'

const navigation = [
  {
    name: 'ORDER ONLINE',
    href: 'https://order.mealkeyway.com/customer/release/index?mid=763370766f70376b797a4f4d2f5a7a397634636872673d3d#/main',
  },
  // {
  //   name: 'CALL TO ORDER',
  //   href: 'tel:+1630-529-2828',
  // },
  // { name: 'FOOD MENU', href: 'https://cdn.flow.page/images/17fa13a5-4223-406b-8674-dbb7d71e8ed4-pdf?m=1632592617' },
  { name: 'FOOD MENU', href: foodMenu },
  { name: 'DRINK MENU', href: 'https://www.cloudteausa.com/menu' },
  // { name: 'LUNCH MENU', href: 'https://cdn.flow.page/images/9f30823c-1d80-480d-bb99-8580cd407296-pdf?m=1630784333' },
  { name: 'LUNCH MENU', href: lunchMenu },
  { name: 'CARRYOUT MENU', href: carryOutMenu },
  // { name: 'VIRTUAL TOUR', href: '/virtual_tour' },
  // { name: 'GIFT CARDS', href: '#giftcards' },
  // { name: 'NEWS', href: '#news' },
  { name: 'CONTACT US', href: '#contactus' },
  { name: 'ABOUT US', href: '#about' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <nav className='flex items-center justify-between md:flex md:justify-between md:m-3 lg:m-3'>
      <a
        href='/'
        className='inline-block outline-none focus:ring-2 focus:ring-yellow-100 focus:ring-inset md:inline-block'
      >
        <img
          className='inline-block w-auto m-3 h-14 sm:h-14 sm:object-contain'
          src={logoWhite}
          alt='logo'
        />
      </a>

      <div className="hidden md:flex md:flex-wrap md:justify-end lg:ml-4">
        {navigation.map(item => (
          <a
            key={item.name}
            href={item.href}
            className='block px-4 py-2 text-sm text-gray-100 outline-none focus:ring-2 focus:ring-yellow-100 focus:ring-inset hover:text-yellow-600 md:text-base lg:text-sm'
            style={{ '&:hover': { borderBottom: '2px solid #e67e22' } }}
          >
            {item.name}
          </a>
        ))}
      </div>

      <Menu as='div' role="menu" className="block md:hidden">
        <Menu.Button className='outline-none'>
          {open ? (
            <svg
              className='w-6 h-6 m-4 text-white fill-current sm:w-9 sm:h-9'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              onClick={() => setOpen(!open)}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h16M4 18h16'
              ></path>
            </svg>
          ) : (
            <svg
              className='w-6 h-6 m-4 text-white fill-current sm:w-9 sm:h-9'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              onClick={() => setOpen(!open)}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h16M4 18h16'
              ></path>
            </svg>
          )}
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute w-40 py-1 origin-top-right bg-white rounded-md shadow-lg right-14 -mt-14 ring-1 ring-black ring-opacity-5 focus:outline-none sm:right-16">
            {navigation.map(item => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <a
                    href={item.href}
                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                  >
                    {item.name}
                  </a>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </nav>
  );
};
