import React from 'react'

export const ImageGalleryMd = () => {
	return (
		<section className="section-meals mt-8 hidden md:block">
			<ul className="meals-showcase clearfix">
				<li>
					<figure className="meal-photo">
						<img src={require('../img/1-1.png').default} alt="5 Spice kitchen" />
					</figure>
				</li>
				<li>
					<figure className="meal-photo">
						<img src={require('../img/2.jpg').default} alt="5 Spice kitchen" />
					</figure>
				</li>
				<li>
					<figure className="meal-photo">
						<img src={require('../img/3.jpg').default} alt="5 Spice kitchen" />
					</figure>
				</li>
				<li>
					<figure className="meal-photo">
						<img src={require('../img/4.jpg').default} alt="5 Spice kitchen" />
					</figure>
				</li>
			</ul>
			<ul className="meals-showcase clearfix">
				<li>
					<figure className="meal-photo">
						<img src={require('../img/5.jpg').default} alt="5 Spice kitchen" />
					</figure>
				</li>
				<li>
					<figure className="meal-photo">
						<img src={require('../img/6.jpg').default} alt="5 Spice kitchen" />
					</figure>
				</li>
				<li>
					<figure className="meal-photo">
						<img src={require('../img/7.jpg').default} alt="5 Spice kitchen" />
					</figure>
				</li>
				<li>
					<figure className="meal-photo">
						<img src={require('../img/8.jpg').default} alt="5 Spice kitchen" />
					</figure>
				</li>
			</ul>
		</section>
	)
}
