import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const ImageGallery = () => {
	return (
		<div>
			<Carousel autoPlay={true} infiniteLoop={true} className="mt-8 md:hidden" swipeable={true} >
				<div><img className="" src={require('../img/1-1.png').default} alt="image1"></img></div>
				<div><img className="" src={require('../img/2.jpg').default} alt="image1"></img></div>
				<div><img className="" src={require('../img/3.jpg').default} alt="image1"></img></div>
				<div><img className="" src={require('../img/4.jpg').default} alt="image1"></img></div>
				<div><img className="" src={require('../img/5.jpg').default} alt="image1"></img></div>
				<div><img className="" src={require('../img/6.jpg').default} alt="image1"></img></div>
				<div><img className="" src={require('../img/7.jpg').default} alt="image1"></img></div>
				<div><img className="" src={require('../img/8.jpg').default} alt="image1"></img></div>
			</Carousel>
		</div>
	)
}
