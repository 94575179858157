import React from 'react';
import { ImageGallery } from './ImageGallery';
import { ImageGalleryMd } from './ImageGalleryMd';

export const About = () => {
  return (
    <section className='text-gray-900 mt-6 ' id='about'>
      <div className='flex flex-col items-center justify-between'>
        <h2 className='uppercase font-normal text-xl sm:text-3xl'>
          Five Spice Kitchen
        </h2>
        <span className='border-b-2 border-yellow-600 h-2 block w-20 mt-4 sm:w-28'></span>
        <p className='mt-4 mx-4 leading-relaxed font-light tracking-wide sm:text-lg'>
          Five Spice Kitchen is a Pan-Asian restaurant offering satisfying
          Chinese classics to popular Asian dishes with a healthy focus to cater
          today's dietary needs. Our menu features favorites like ramen, a
          variety of vegan and vegetarian options, and seasonal specialties. All
          of our dishes are made from fresh, locally sourced ingredients
          thoughtfully chosen to cause the least harm to animals and the
          environment.
        </p>
      </div>
      <ImageGallery />
      <ImageGalleryMd />
      <div className='md:flex md:flex-row'>
        <div className='flex justify-items-start mt-8 flex-col mx-3 md:w-4/6 '>
          <div className='flex items-center'>
            <svg
              className='w-10 h-10 text-yellow-600'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1'
                d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z'
              ></path>
            </svg>
            <p className='font-light tracking-wide text-2xl ml-3 sm:text-3xl'>
              Private Events
            </p>
          </div>
          <div>
            <p className='mt-4 leading-relaxed font-light tracking-wide sm:text-lg'>
              We offer a family-friendly atmosphere in a modern style and pride
              ourselves on delivering an exceptional customer experience with
              warm and attentive service. We can also provide party room and
              custom party menu. Please call{' '}
              <a href='tel:+16305292828'>630-529-2828</a> or{' '}
              <a href='mailto:info@5spicekitchen.com'>
                email us at info@5spicekitchen.com
              </a>{' '}
              for more detail.{' '}
            </p>
          </div>
        </div>
        <div className='flex justify-items-start mt-8 flex-col mx-3 md:w-4/6'>
          <div className='flex items-center'>
            <svg
              className='w-10 h-10 text-yellow-600 fill-current'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 60 60'
            >
              <path d='M18.35 20.805a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414 2.61 2.61 0 0 1 0-3.684c.87-.87 1.35-2.026 1.35-3.256s-.479-2.386-1.35-3.256a.999.999 0 1 0-1.414 1.414c.492.492.764 1.146.764 1.842s-.271 1.35-.764 1.842a4.61 4.61 0 0 0 0 6.512zM40.35 20.805a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414 2.61 2.61 0 0 1 0-3.684c.87-.87 1.35-2.026 1.35-3.256s-.479-2.386-1.35-3.256a.999.999 0 1 0-1.414 1.414c.492.492.764 1.146.764 1.842s-.271 1.35-.764 1.842a4.61 4.61 0 0 0 0 6.512zM29.35 14.805a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414 2.61 2.61 0 0 1 0-3.684c.87-.87 1.35-2.026 1.35-3.256s-.479-2.386-1.35-3.256a.999.999 0 1 0-1.414 1.414c.492.492.764 1.146.764 1.842s-.271 1.35-.764 1.842a4.61 4.61 0 0 0 0 6.512zM25.345 28.61c.073 0 .147-.008.221-.024a20.141 20.141 0 0 1 4.421-.488H30a1 1 0 1 0 0-2h-.014a22.17 22.17 0 0 0-4.86.537 1 1 0 0 0 .219 1.975zM9.821 45.081a1 1 0 0 0 1.163-.803c1.039-5.698 4.473-10.768 9.186-13.56a1 1 0 0 0-1.019-1.721c-5.204 3.083-8.992 8.661-10.134 14.921a.998.998 0 0 0 .804 1.163z' />
              <path d='M55.624 43.721C53.812 33.08 45.517 24.625 34.957 22.577c.017-.16.043-.321.043-.48 0-2.757-2.243-5-5-5s-5 2.243-5 5c0 .159.025.32.043.48C14.483 24.625 6.188 33.08 4.376 43.721 2.286 44.904 0 46.645 0 48.598c0 5.085 15.512 8.5 30 8.5s30-3.415 30-8.5c0-1.953-2.286-3.694-4.376-4.877zM27.006 22.27A3.004 3.004 0 0 1 30 19.098a3.004 3.004 0 0 1 2.994 3.172c-.047-.005-.094-.007-.14-.012a25.32 25.32 0 0 0-1.038-.089c-.128-.009-.255-.022-.383-.029-.474-.026-.951-.041-1.432-.041s-.958.015-1.432.041c-.128.007-.255.02-.383.029-.348.024-.694.052-1.038.089-.048.005-.095.006-.142.012zm-.607 2.098a24.358 24.358 0 0 1 1.952-.207 24.54 24.54 0 0 1 3.632.025c.542.044 1.082.102 1.619.182 10.418 1.575 18.657 9.872 20.152 20.316.046.321.083.643.116.965.011.111.026.221.036.332.039.443.068.886.082 1.329a106.717 106.717 0 0 1-47.974 0c.015-.443.043-.886.082-1.329.01-.111.024-.221.036-.332.033-.323.07-.645.116-.965 1.494-10.444 9.733-18.742 20.151-20.316zM30 55.098c-17.096 0-28-4.269-28-6.5 0-.383.474-1.227 2.064-2.328-.004.057-.002.113-.006.17-.034.548-.058 1.1-.058 1.658v.788l.767.185c8.254 1.98 16.744 2.972 25.233 2.972s16.979-.991 25.233-2.972l.767-.185v-.788c0-.558-.024-1.109-.058-1.658-.004-.057-.002-.113-.006-.17C57.526 47.371 58 48.215 58 48.598c0 2.231-10.904 6.5-28 6.5z' />
            </svg>

            <p className='font-light tracking-wide text-2xl ml-3 sm:text-3xl'>
              Catering Events
            </p>
          </div>
          <div>
            <p className='mt-4 leading-relaxed font-light tracking-wide sm:text-lg'>
              We aim to be the Chicago suburb go-to neighborhood restaurant for
              Asian cuisine and as a full service restaurant with carryout and
              delivery options, we also provide catering. With Five Spice
              Kitchen catering, you can celebrate life's special moments with a
              meal everyone will love.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
