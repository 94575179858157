import React from 'react'
import cloudTea from '../img/cloudtea.png'

export const News = () => {
	return (
		<section className="text-gray-900 mt-8 flex items-center justify-around md:w-8/12 md:m-auto md:mt-8" id="news">
			<div className="flex flex-col items-center justify-between">
				<h2 className="uppercase font-normal text-xl sm:text-3xl">News</h2>
				<span className="border-b-2 border-yellow-600 h-2 block w-20 mt-4"></span>
				<div className="">
					<a href="https://www.cloudteausa.com/"><img className="h-32 ml-3 float-left mt-4 mr-3" src={cloudTea} alt="cloud tea logo" /></a>
					<p className="mt-4 mx-4 leading-relaxed font-light tracking-wide sm:text-lg">We are proud to announce the partnership with "CLOUD TEA" to offer delicious beverages from the classic bubble teas to specialty drinks to our customers while dining at Five Spice Kitchen.
					</p>
				</div>
			</div>
		</section>
	)
}
